/*! SVG Türkiye Haritası | CSS | MIT Lisans | dnomak.com */

.ReactModal__Overlay  {
  z-index:999999;

}


.svg-turkiye-haritasi {
    max-width: 1140px;
    margin: 0 auto;
    text-align: center;
  }
  .svg-turkiye-haritasi svg {
    width: 100%;
    height: auto;
  }
  .il-isimleri {
    position: absolute;
    z-index: 2;
  }
  .denemediv, .il-isimleri div {
    font-family: 'Open Sans';
    display: inline-block;
    background: #1094F6;
    color: #fff;
    padding: 8px 16px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }
  #svg-turkiye-haritasi path {
    cursor: pointer;
    fill: #c6aad8;
  }
  #svg-turkiye-haritasi path:hover {
    fill: #555;
  }
  #guney-kibris {
    pointer-events: none;
  }
  


  