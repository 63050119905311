.bannerTwoImgStyle {
    height:492px !important;
     width:100%;
     background-size: cover;
}


.bannerCustomH1 {
   font-size:40px !important;
}


.slick-arrow.slick-prev {
    display: block;
    left: 0px !important;
    z-index: 9999;
}

.slick-arrow.slick-next{
    right:0px !important;
    content: "&#8594;";
}
.slick-arrow.slick-prev, .slick-arrow.slick-next {
    top:500px;
    z-index: 9999 !important;
    padding-top: 0px !important;
    font-weight: bold !important;
    font-size: 23px;
}

.slick-dots li.slick-active button {
    border: none !important;
    background: #7b73c3 !important;
}


.slick-dots li button {
    background-color: #e5e0e6c2 !important;
    border: 1px solid #747162 !important;
    border-radius: 20px !important;
    /*text-indent: -9999px !important;*/
    color: black !important;
    float: left !important;
    width: 25px !important;
    height: 25px !important;
}



.slick-dots {

    position:relative !important;
    background: #5ec375;
    top:-2px !important;
    bottom:0px !important
}


@media screen and (max-width: 1328px ) and (min-width: 1176px) {
    .slick-arrow.slick-prev, .slick-arrow.slick-next {
        top:550px !important;

        padding-top: 0px !important;
    }
}


@media screen and (max-width: 1175px ) {
    .slick-arrow.slick-prev, .slick-arrow.slick-next {
        top:650px !important;

        padding-top: 0px !important;
    }
}


@media screen and (max-width: 685px) {
    .slick-arrow.slick-prev, .slick-arrow.slick-next {
        top:550px !important;

        padding-top: 0px !important;
    }
}


@media screen and (min-width: 722px) and (max-width: 1006px) {
    div.environment-main-content {
        margin-top: 10px !important;
    }
}

@media screen and (max-width: 722px) {
    div.environment-main-content {
        margin-top: 57px !important;
    }
}


@media screen and (max-width: 1004px) {
    .environment-banner-caption {
        display: block !important;
    }

    .environment-banner-caption .banner-thumb {
        width: 100% !important;
        place-content: center !important;
        margin-top: 50px !important;
    }

    .environment-banner-caption .banner-caption-text {
        display:none;
    }


    .slick-slider.slick-initialized {
        height: 350px !important;
    }

    .slick-list {
        height: 350px !important;
    }

    .slick-track{
        height: 350px !important;
    }
    .environment-banner-one-layer {
        height: 350px !important;
    }
    .environment-banner-one-layer > img {
        height: 350px !important;
    }

    .environment-banner-caption .container {
        height: 350px !important;
    }



    .environment-banner-caption .banner-thumb {
        height: 350px !important;
    }

    .environment-banner-caption .banner-thumb.video {
        margin-top:15px !important;
    }

    .environment-banner-caption .banner-thumb.video .ytb_video {
        height: 300px !important;
    }
}
