.headerContainerSubDiv {
    margin-bottom:30px;
}

.containerSubSecondDiv{
    display:flex; justify-content: center;
}

.headerLogoA{
    margin:0px 0px !important; 
    width:auto !important;
    height:93px;
}

.headerLogoImg {
    height:93px; margin-top:12px;
}

.headerUlStyle{
    margin-top:22px !important;
}